import React, { useState, useEffect } from 'react';
import EditUser from './edit-user';
import { API, graphqlOperation } from 'aws-amplify';
import { GetUser } from '../../lib/graphql-command/user-command';

const Profile = () => {
    const [user, setUser] = useState();

    useEffect(() => {
        API.graphql(graphqlOperation(GetUser))
            .then(result => setUser(result.data.getUser))
            .catch(err => console.log(err))
    }, [])

    return (
        <div>
            {user && <EditUser UserID={user.UserID} />}
        </div>
    )
}

export default Profile;